<template>
  <v-card
    v-if="user"
    tile
  >
    <v-layout
      column
      align-center
    >
      <v-avatar
        size="130"
        class="mt-4"
      >
        <v-img src="@/assets/img-user.jpg" />
      </v-avatar>
      <v-card-text class="text-h5 text-center py-0">
        {{ user.name }}
      </v-card-text>
      <v-card-text
        v-if="user.roles.length > 0"
        class="text-center pt-1 pb-0"
      >
        <v-chip
          v-for="(role, i) in user.roles"
          :key="i"
          color="secondary"
          class="ma-1"
        >
          {{ role.display_name }}
        </v-chip>
      </v-card-text>
    </v-layout>
    <v-list>
      <v-list-item selectable>
        <v-list-item-icon>
          <v-icon color="indigo">
            mdi-counter
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>#{{ user.id }}</v-list-item-title>
          <v-list-item-subtitle>ID</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item selectable>
        <v-list-item-icon>
          <v-icon color="indigo">
            mdi-email
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ user.email }}</v-list-item-title>
          <v-list-item-subtitle>{{ $t('messages.email') }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider />
    <v-card-text class="pa-0">
      <v-simple-table>
        <template #default>
          <tbody>
            <tr v-if="user.created_at">
              <td>
                <small
                  class="text-uppercase text-muted font-weight-bold"
                  style="color: rgba(0, 0, 0, 0.54)"
                >
                  {{ $t('messages.created_at') }}
                </small>
              </td>
              <td>{{ user.created_at | moment('YYYY-MM-DD HH:mm:ss') }}</td>
            </tr>
            <tr v-if="user.updated_at">
              <td>
                <small
                  class="text-uppercase text-muted font-weight-bold"
                  style="color: rgba(0, 0, 0, 0.54)"
                >
                  {{ $t('messages.updated_at') }}
                </small>
              </td>
              <td>{{ user.updated_at | moment('YYYY-MM-DD HH:mm:ss') }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'UserProfile',
  props: {
    user: {
      type: Object,
      required: true
    }
  }
}
</script>
