<template>
  <v-container
    fluid
    fill-height
  >
    <v-layout
      align-center
      justify-center
    >
      <v-flex
        xs12
        sm8
        md4
        lg4
      >
        <UserProfile
          v-if="user"
          :user="user"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import UserProfile from '@/components/Profile'
import authHeader from '@/utils'

export default {
  components: {
    UserProfile
  },
  data: () => ({
    user: null
  }),
  mounted () {
    this.$http
      .get('/users/' + this.$route.params.id + '/view', { headers: authHeader() })
      .catch(error => this.displayError(error))
      .then((response) => {
        this.user = response.data
      })
  }
}
</script>
